<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet :title="title" class="kt-section__title-lg">
          <template v-slot:toolbar>
            <b-button
              size="md"
              variant="success"
              id="download_button"
              :title="$t('ATTENDANCE.DOWNLOAD_EXCEL')"
              v-b-tooltip.hover
              v-on:click="downloadExcelFile"
            >
              <i
                class="fa fa-file-download"
                style="font-size: 1.3em; margin-left: 0;"
              ></i>
            </b-button>
          </template>
          <template v-slot:body>
            <b-form class="kt-form">
              <b-row>
                <b-table
                  :fields="fields"
                  :items="report"
                  :busy="isBusy"
                  striped
                  bordered
                  head-variant="dark"
                  show-empty
                >
                  <template v-slot:empty="scope">
                    <p class="text-center">
                      {{ $t("QUIZ.NO_QUIZ") }}
                    </p>
                  </template>
                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                    </div>
                  </template>
                </b-table>
              </b-row>
            </b-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";

export default {
  data() {
    return {
      title: this.$t("MENU.QUIZZES_REPORT"),
      isBusy: true,
      fields: [
        { number: this.$t("GENERAL.NUMBER") },
        { student_id: this.$t("PROFILE.ID") },
        { name: this.$t("PROFILE.NAME") },
        { father_name: this.$t("PROFILE.FATHER_NAME") }
      ],
      report: []
    };
  },
  components: {
    KTPortlet
  },
  methods: {
    getQuizzesReport(id) {
      this.isBusy = true;
      this.errors = [];
      let that = this;
      ApiService.get("lecturer/quizzes-report/" + id)
        .then(({ data }) => {
          let quizzes_header = data["quizzes"].map(function(quiz) {
            return {
              key: "quiz" + quiz,
              label: that.$t("QUIZ.NUMBER", { number: quiz }),
              class: "text-center"
            };
          });
          this.fields = this.fields.concat(quizzes_header);
          this.fields.push(
            {
              key: "total_marks",
              label: this.$t("QUIZ.TOTAL_MARKS"),
              class: "text-center"
            },
            {
              key: "average_marks",
              label: this.$t("QUIZ.AVERAGE_MARKS"),
              class: "text-center"
            },
            {
              key: "obtained_marks",
              label: this.$t("QUIZ.OBTAINED_MARK"),
              class: "text-center"
            }
          );
          this.report = data["students"].map(function(student, index) {
            let student_object = {
              number: index + 1,
              student_id: student["id"],
              name: student["full_name"],
              father_name: student["father_name"],
              total_marks: student["total_marks"],
              average_marks: student["average_marks"],
              obtained_marks: student["obtained_marks"],
              _cellVariants: { student_id: " text-right" }
            };
            for (let quiz of Object.keys(student["quizzes"])) {
              student_object["quiz" + student["quizzes"][quiz]["quiz_id"]] =
                student["quizzes"][quiz]["mark"] ?? 0;
            }
            return student_object;
          });
          this.isBusy = false;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.isBusy = false;
        });
    },
    downloadExcelFile() {
      const downloadButton = document.getElementById("download_button");
      downloadButton.classList.add(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right"
      );
      downloadButton.disabled = true;
      ApiService.get(
        "lecturer/quizzes-report/download/" + this.$route.params.id
      )
        .then(({ data }) => {
          let file = document.createElement("a");
          file.href = data["data"];
          file.download = data["fileName"];
          file.click();
          file.remove();
          downloadButton.classList.remove(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right"
          );
          downloadButton.disabled = false;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          downloadButton.classList.remove(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right"
          );
          downloadButton.disabled = false;
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.QUIZZES_REPORT"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.post("lecturer/class-information", { id: this.$route.params.id })
      .then(({ data }) => {
        this.title +=
          " " +
          this.$t("ATTENDANCE.TITLE", {
            class: data.class_name,
            subject: data.subject,
            time: data.class_time,
            semester: data.semester,
            faculty: data.faculty
          });
        this.getQuizzesReport(this.$route.params.id);
      })
      .catch(({ response }) => {
        if (response && (response.status === 403 || response.status === 422)) {
          this.$router.push({ name: "lecturerAssignedClasses" });
        }
      });
  }
};
</script>
